import React from 'react';
export default function Itch() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
    >
      <path d="M3.129 1.338c-1.047 0.622-3.109 2.991-3.129 3.612v1.029c0 1.304 1.219 
        2.45 2.325 2.45 1.329 0 2.436-1.101 2.436-2.408 0 1.307 1.069 2.408 2.398 
        2.408s2.364-1.101 2.364-2.408c0 1.307 1.137 2.408 2.466 2.408h0.024c1.329 0 
        2.466-1.101 2.466-2.408 0 1.307 1.035 2.408 2.364 2.408s2.398-1.101 2.398-2.408c0 
        1.307 1.107 2.408 2.436 2.408 1.107 0 
        2.325-1.146 2.325-2.45v-1.029c-0.020-0.621-2.082-2.991-3.129-3.612-3.254-0.114-5.51-0.134-8.871-0.133s-7.945 
        0.053-8.871 0.133zM9.506 7.815c-0.133 0.23-0.288 0.428-0.467 0.601l-0.001 
        0.001c-0.502 0.49-1.189 0.794-1.947 0.794-0.001 0-0.002 0-0.003 0-0.759 
        0-1.447-0.303-1.949-0.795l0 0c-0.182-0.178-0.32-0.368-0.446-0.59l-0.001 
        0c-0.126 0.222-0.302 0.412-0.485 0.59-0.502 0.491-1.19 0.794-1.949 0.794-0.001 
        0-0.002 0-0.003 0h0c-0.091 0-0.186-0.025-0.263-0.052-0.107 1.112-0.152 
        2.175-0.168 2.95l-0 0.004c-0.002 0.394-0.004 0.717-0.006 1.167 0.021 
        2.334-0.231 7.564 1.029 8.849 1.953 0.455 5.546 0.663 9.151 0.664h0.001c3.605-0.001 
        7.198-0.209 9.151-0.664 1.26-1.285 1.008-6.516 
        1.029-8.849-0.002-0.45-0.004-0.773-0.006-1.167l-0-0.004c-0.016-0.775-0.061-1.838-0.168-2.95-0.077 
        0.026-0.172 0.052-0.263 0.052-0.001 0-0.002 0-0.002 0-0.759 
        0-1.447-0.303-1.949-0.795l0.001 0c-0.182-0.178-0.358-0.368-0.485-0.59l-0.001-0c-0.127 
        0.222-0.265 0.412-0.446 0.59-0.502 0.491-1.19 0.795-1.948 0.795-0.001 0-0.002 0-0.003 
        0h0c-0.758 0-1.445-0.304-1.947-0.795-0.179-0.174-0.334-0.372-0.461-0.589l-0.007-0.013c-0.132 
        0.23-0.286 0.428-0.463 0.602l-0 0c-0.502 0.491-1.19 0.795-1.949 0.795-0.001 0-0.002 0-0.003 
        0h0c-0.026 0-0.053-0.001-0.079-0.002h-0.001c-0.026 0.001-0.053 0.002-0.080 0.002-0.001 0-0.002 
        0-0.003 0-0.759 0-1.447-0.303-1.949-0.795l0.001 0c-0.178-0.174-0.331-0.372-0.456-0.589l-0.007-0.013zM7.502 
        10.406l-0 0.001h0.001c0.794 0.002 1.498 0 2.372 0.953 0.687-0.072 1.406-0.108 2.125-0.107h0.001c0.719-0.001 
        1.437 0.035 2.125 0.107 0.873-0.953 1.578-0.952 2.372-0.953h0.001l-0-0.001c0.375 0 
        1.875 0 2.92 2.935l1.122 4.026c0.832 2.995-0.266 3.069-1.636 3.071-2.031-0.076-3.156-1.551-3.156-3.026-1.124 
        0.184-2.436 0.276-3.748 0.276h-0.001c-1.312 0-2.624-0.092-3.748-0.276 0 1.475-1.125 2.95-3.156 
        3.026-1.37-0.003-2.468-0.076-1.636-3.071l1.123-4.026c1.045-2.935 2.545-2.935 2.92-2.935zM12 
        12.713v0.001c-0.002 0.002-2.138 1.964-2.523 2.662l1.399-0.056v1.22c0 0.057 0.561 0.034 1.123 
        0.008h0.001c0.562 0.026 1.123 0.049 1.123-0.008v-1.22l1.399 0.056c-0.384-0.698-2.523-2.662-2.523-2.662v-0.001l-0 0z"
      />
    </svg>
  );
}
