import React, { useEffect } from 'react';
import anime from "../utils/anime.es";

const anims = {}

export default function HelloSVG ({isShowing}) {
  const head= React.useRef(null)
  const laptop= React.useRef(null)
  const light= React.useRef(null)
  const arrow= React.useRef(null)
  const cursor= React.useRef(null)
  const git= React.useRef(null)
  const g= React.useRef(null)
  const e= React.useRef(null)
  const i= React.useRef(null)
  const t = React.useRef(null)
  const lEye = React.useRef(null)
  const rEye = React.useRef(null)
  const lLash = React.useRef(null)
  const rLash = React.useRef(null)
  
  useEffect(() => {
      anims.flyIn = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: [
          cursor.current,
          arrow.current,
          laptop.current,
          head.current,
        ],
        translateY: [
          {value: [200, 0]}
        ],
        opacity: [0, 1],
        duration: 1000,
        delay: anime.stagger(100, { from: "center" }),
        complete: () => {
          anims.eyes.play();
          anims.git.play();
          anims.light.play();
        }
      })

      anims.light = anime({
        targets: light.current,
        autoplay: false,
        opacity: [
          { value: [0.6, 0.3], duration: 100 },
          { value: [0.3, 0.2], duration: 50 },
          { value: [0.2, 0.5], duration: 100 },
          { value: [0.5, 0.4], duration: 50 },
          { value: [0.4, 0.6], duration: 100 },
          { value: 0.3, duration: 1500 },
          { value: 0.6, duration: 1500 },
        ],
        easing: "easeInOutSine",
        loop: true,
      });

      anims.eyes = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: [lEye.current, rEye.current],
        translateX: [
          { value: 0, duration: 200 },
          { value: 2, duration: 200 },
          { value: -2, duration: 200, delay: 1000 },
          { value: 0, duration: 200, delay: 1000 },
        ],
        translateY: [
          { value: 0, duration: 200 },
          { value: 0, duration: 200 },
          { value: -2, duration: 200, delay: 1000 },
          { value: 0, duration: 200, delay: 1000 },
        ],
        begin: () => {
          if (anims.cursor.began)
            anims.cursor.reset()
        }
      })
      .add({
        targets: [lLash.current, rLash.current],
        translateY: [
          { value: 0, duration: 200 },
          { value: 0, duration: 200 },
          { value: -2, duration: 200, delay: 1000 },
          { value: 0, duration: 200, delay: 1000 },
        ],
      }, 0)

      anims.git = anime
      .timeline({
        autoplay: false,
      })
      .add({
        targets: cursor.current,
        translateY: [
          {value: 0, duration: 0}
        ],
        translateX: [
          { value: 0, duration: 1 },
          { value: 35, duration: 200, delay: 400 },
          { value: 70, duration: 200 },
          { value: 105, duration: 200 },
          { value: 105, duration: 1000 },
          { value: 70, duration: 200 },
          { value: 35, duration: 200 },
          { value: 35, duration: 1000 },
          { value: 70, duration: 200 },
          { value: 105, duration: 200 },
          { value: 105, duration: 200 },
        ],
      })
      .add({
        targets: g.current,
        opacity: [
          { value: 0, duration: 0 },
          { value: 1, duration: 200, delay: 400 },
        ],
      }, 0)
      .add({
        targets: i.current,
        opacity: [
          { value: 0, duration: 0 },
          { value: 0, duration: 200, delay: 400 },
          { value: 0, duration: 200 },
          { value: 0, duration: 200 },
          { value: 0, duration: 1000 },
          { value: 0, duration: 200 },
          { value: 0, duration: 200 },
          { value: 0, duration: 1000 },
          { value: 1 },
        ],
      }, 0)
      .add({
        targets: e.current,
        opacity: [
          { value: 0, duration: 0 },
          { value: 0, duration: 200, delay: 400 },
          { value: 1, duration: 200 },
          { value: 1, duration: 200 },
          { value: 1, duration: 1000 },
          { value: 1, duration: 200 },
          { value: 0, duration: 200 },
        ],
      }, 0)
      .add({
        targets: t.current,
        opacity: [
          { value: 0, duration: 0 },
          { value: 0, duration: 200, delay: 400 },
          { value: 0, duration: 200 },
          { value: 1, duration: 200 },
          { value: 1, duration: 1000 },
          { value: 0, duration: 200 },
          { value: 0, duration: 200 },
          { value: 0, duration: 1000 },
          { value: 0, duration: 200 },
          { value: 1, duration: 200 },
          { value: 1, duration: 200 },
        ],
        complete: () => {
          if(!anims.cursor.began)
            anims.cursor.play();
        }
      }, 0)

      anims.cursor = anime({
        autoplay: false,
        targets: cursor.current,
        opacity: [
          { value: 1, duration: 600 },
          { value: 0, duration: 0 },
          { value: 0, duration: 300 },
        ],
        easing: "easeInOutSine",
        loop: true,
      })

  }, [])

  useEffect(() => {
    if(isShowing) {
      if (anims.flyIn.completed && (anims.git.completed || !anims.git.began)) {
        anims.eyes.play();
        anims.git.play();
        anims.light.play();
      }
      if (!anims.flyIn.began) {
        anime.set([
          lEye.current,
          rEye.current,
          lLash.current,
          rLash.current,
        ], {
          opacity:1,
        })
        anime.set([
          g.current,
          e.current,
          i.current,
          t.current,
        ],
        {
          opacity: 0,
        })
        anims.flyIn.play();
      }
    } 
  }, [isShowing])
  
  return (
    <svg version="1.0" id="dev" x="0px" y="0px"
      viewBox="0 0 300 300">
      <defs>
        <style>
          {`
            #head{
              transform-origin:212px 145px;
            }
            #l-lash{
              transform-origin:216.6px 155.3px;
            }
            #l-eye{
              transform-origin:214.8px 158.4px;
            }
            #r-eye{
              transform-origin:183.2px 158.3px;
            }
            #r-lash{
              transform-origin:182.7px 155.4px;
            }
            #arrow{
              transform-origin:39px 36px;
            }
            #cursor{
              transform-origin:76px 58.3px;
            }
            #git{
              transform-origin:109px 38px;
            }
            #g{
              transform-origin:77.1px 43.9px;
            }
            #e{
              transform-origin:109.9px 38px;
            }
            #i{
              transform-origin:110.2px 31.8px;
            }
            #t{
              transform-origin:141.5px 33.4px;
            }
            #laptop{
              transform-origin:155px 181px;
            }
            #light{
              transform-origin:155.6px 177.2px;
            }
          `}
        </style>
      </defs>
    <g ref={laptop} id="laptop">
      <linearGradient id="light_1_" gradientUnits="userSpaceOnUse" x1="101.8149" y1="244.2612" x2="192.6635" y2="64.6385">
        <stop  offset="0" style={{stopColor:"#000000"}}/>
        <stop  offset="9.489626e-02" style={{stopColor:"#000000",stopOpacity:"0.9098"}}/>
        <stop  offset="1" style={{stopColor:"#000000", stopOpacity:"5.000000e-02"}}/>
      </linearGradient>
      <path ref={light} id="light" fill="url(#light_1_)" d="M166.9,272.1l110.9-95c0,0-66.7-138.9-244.3-78.7l3.6,68l125.8,12.3l3.2,3.6L166.9,272.1
        z"/>
      <path d="M211.7,235.2l-0.4-0.8l-42.9-4.4v-45c0-4.3-3.2-7.9-7.5-8.3l-119.4-12c-2.5-0.3-4.9,0.6-6.7,2.4c-1.8,1.8-2.7,4.2-2.4,6.7
        l7.8,85.4c0.2,2.4,2,4.3,4.4,4.6l118.6,15.8c0.2,0,0.4,0,0.6,0c0.6,0,1.1-0.1,1.6-0.3c0.5,0.2,1,0.3,1.7,0.3c1,0,2-0.3,3.1-1.3
        c1.9-1.7,20.7-18.4,21.1-18.8c0.8-0.7,17.2-14.9,19.4-17.2C213.6,239.4,211.8,235.4,211.7,235.2z M43.1,258.8l-7.8-85.4
        c-0.1-1.6,0.4-3.2,1.6-4.3c1-1,2.4-1.6,3.8-1.6c0.2,0,0.4,0,0.5,0l119.4,12c2.8,0.3,4.8,2.6,4.8,5.3v44.7l0,0l0,1.7v42.7
        c-0.1,1.2-0.3,1.9-0.9,2.3c-0.3,0.2-0.6,0.2-1,0.2L44.9,260.8C43.9,260.6,43.2,259.8,43.1,258.8z M208.6,240.3
        c-2.2,2.2-19.1,16.9-19.3,17l0,0c0,0-18.5,16.5-21,18.7c0.1-0.4,0.1-0.7,0.1-1.1v-0.8c0.1-1.8,0-33.3,0-41.1l40.8,4.2
        C209.4,238,209.5,239.3,208.6,240.3z"/>
      <path id="peace" d="M109.4,221.3c0.1-1.1,0.3-2.2,0.4-3.3c0.3-2.1-0.3-4-1.6-5c-1-0.8-2.2-0.9-3.3-0.4c-0.2,0.1-0.4,0.2-0.5,0.3
        c-0.4-0.9-1-1.4-1.6-1.8c-1.2-0.7-2.8-0.6-3.9,0.4c-0.3,0.2-0.5,0.5-0.7,0.8c-0.1-2.8-0.3-5.7-0.3-8.1c-0.1-3.3-2-5.2-3.8-5.5
        c-1.7-0.3-3.2,0.6-3.9,2.4c-0.4,1.1-0.6,2.9-0.6,5.1c-1-1.7-2.1-2.7-3.4-2.9c-1.3-0.2-2.6,0.4-3.5,1.8c-1.9,2.8,1.9,13.3,4.3,19.3
        c0,0,0,0,0,0c-1.3,2.1-1.8,4.9-1.3,8c0.7,3.9,2.9,7.5,5.9,9.6c1.6,1.1,3.2,1.7,4.8,1.7c0.4,0,0.8,0,1.2-0.1
        c1.8-0.3,3.1-1.4,3.9-2.1c1.7,0.4,5.3,0.7,7.9-1.5C112.9,236.9,113.7,228.2,109.4,221.3z M106.1,215.3c0,0,0.1-0.1,0.3,0.1
        c0.3,0.3,0.7,1,0.5,2.2c-0.2,1.2-0.3,2.4-0.5,3.5c-0.3,2.4-0.6,4.8-1.2,6c0,0,0-0.1,0-0.1c-0.1-0.2-0.1-0.3-0.2-0.5c0,0,0,0,0,0
        c0.5-1.4,0.3-2.8-0.6-3.8c0-0.1,0-0.1,0.1-0.1c0.4-1.8,0.6-3.4,0.7-4.7c0.1-0.2,0.1-0.4,0.2-0.6
        C105.4,215.9,105.9,215.4,106.1,215.3z M100.9,213.7c0.1-0.1,0.3-0.1,0.4-0.1c0.6,0.4,1.4,2.4,0.4,7.5c-0.6-0.2-1.3-0.4-2-0.6
        C99.5,218.1,99.8,214.7,100.9,213.7z M92.9,202.2c0.2-0.4,0.3-0.6,0.6-0.5c0.5,0.1,1.3,1,1.3,2.6c0.1,5.1,0.6,12.8,0.8,15.9
        c-0.7,0-1.4,0.1-2,0.2C92.8,214.1,92,204.5,92.9,202.2z M85.1,206.6c0.2-0.4,0.4-0.5,0.5-0.5c0.4,0.1,1.9,1.4,3.1,6.3
        c1.2,5,1.7,7.5,2,8.7c-0.5,0.2-1,0.5-1.5,0.8C86.7,215.4,84.5,208,85.1,206.6z M107.4,237.6c-2.2,1.9-6,0.7-6,0.7
        c-0.6-0.2-1.3,0-1.7,0.6c-0.4,0.6-2.6,3.2-6.4,0.6c-2.4-1.7-4.1-4.6-4.7-7.7c-0.4-2.4-0.1-4.6,1-6c2.5-3.5,9.1-3.1,12.5-1.2
        c0.1,0.1,0.4,0.2,0.2,0.8c-0.3,0.9-1.7,2.1-4.1,1.6c-1.8-0.4-3.6-0.2-4.7,0c-0.8,0.1-1.4,0.9-1.3,1.7c0.1,0.8,0.9,1.4,1.7,1.3
        c4.2-0.6,6,0.4,6.8,3.8c0.2,0.7,0.8,1.2,1.5,1.2c0.1,0,0.2,0,0.3,0c0.8-0.2,1.3-1,1.1-1.8c-0.3-1.5-0.9-2.7-1.5-3.6
        c0.3-0.1,0.6-0.3,0.9-0.5c0.4,0.6,0.9,1.2,1.7,1.4c0.1,0,0.3,0.1,0.5,0.1c0.5,0,1.3-0.2,2-1c0.6-0.7,1.1-1.7,1.4-2.9
        C110.1,231.4,109.2,236.1,107.4,237.6z"/>
    </g>
    <g ref={cursor} id="cursor">
      <rect x="57.1" y="52.8" width="38.5" height="11"/>
    </g>
    <g ref={arrow} id="arrow">
      <polygon points="32.7,59.6 24.4,52.5 38.6,35.8 24.4,19.2 32.7,12.1 53,35.8 		"/>
    </g>
    <g ref={head} id="head">
      <g>
        <path d="M207.2,179.3c-1.8,0.3-3.4,0.4-4.8,0.5l0.1-0.8c-7.3-1.1-10.8-4.5-11-5.4c3-19.9,9.3-38.3,9.4-38.5l-2.8-1
          c-0.1,0.2-6.4,18.8-9.5,39.1c-0.1,0.8,0.1,1.7,0.8,2.6c0.7,1,2.1,2.2,3.9,3.2c-3.3,1.1-11.5,3.8-11.8,5c-0.4,1.4-0.5,5.8,0.4,6.5
          c1.8,1.2,8.6-0.1,8.6-0.1c8.7,2,12.7-0.1,12.7-0.1c2.4,1.8,11.4,0.4,11.4,0.4s8.6,2.1,9.4,0.6c0.8-1.5,1-7.1,0.2-7.5
          C217.7,181.9,207.2,179.3,207.2,179.3z"/>
        <path d="M225.9,135.8c-1.6-1.2-18.1,2.4-21.1,3.5c-3,1.1-3.6,6.2-1.1,7.9c2.5,1.7,19.3-0.9,22.3-3.4
          C229,141.3,227.8,137.2,225.9,135.8z"/>
        <path d="M270.9,131.3l4.3-0.6l-3.8-2.2c-4.3-2.5-4.4-5-4.4-8.7c-0.1-3.2-0.2-7.1-3.5-11.5c-5.3-6.9-11-7.2-13.9-6.8
          c-1-5.5-5.5-22.8-24.1-24.9c-13.3-1.5-18.6,3.8-24.2,9.3c-3.7,3.6-7.4,7.4-13.9,9.7c-15.8,5.7-26.1-1.3-26.5-1.6l-3.1-2.2l0.8,3.7
          c1.7,8.2,7.3,12.7,10.1,14.6c-6.9,7.7-16.1,7-16.5,6.9l-2.7-0.2l1.2,2.4c3.1,6.1,10.4,8.5,14.8,9.5c-2.8,2.1-5.3,4-5.3,4l-2.5,1.8
          l3,0.8c2.7,0.7,5.4,1,8.1,1c1.9,0,3.8-0.2,5.7-0.5c0,0.8,0,1.6,0,2.4c-0.1,0-0.1,0.1-0.2,0.1c-1.5,1-2.8,4.7-1.3,5.8
          c0.2,0.2,0.7,0.4,1.4,0.6c-0.2,45.8,6.4,65.7,6.7,66.6l0.3,0.9l1,0.1c3,0.2,5.9,0.3,8.7,0.3c35.2,0,55.4-16.3,56.3-17.1l0.4-0.3
          l0.1-0.5c1.8-8.5,3.1-16.5,4-23.4c1.1,0.4,2.2,0.7,3.2,0.7c2.9,0,5.7-1.6,8.1-4.9c5.1-6.9,7.5-20.1,2.9-23.8c-1.9-1.5-4-2-6-1.7
          l0,0l-1.2-3.4l3.4-4.4l-1.1-3C265.7,132,270.6,131.4,270.9,131.3z M180.9,112.2c6.9,1.5,14.1-4.1,21.8-10.1
          c7.8-6.1,15.9-12.4,24.4-11.4c7.7,0.9,11.3,5.3,13,8.9c3.2,6.8,2,15.8-1,20.5l-1.5-1c2.7-4.2,3.8-12.6,0.9-18.7
          c-2.1-4.5-6.1-7.3-11.6-7.9c-7.7-0.9-15.5,5.2-23.1,11c-8,6.2-15.5,12.1-23.2,10.4L180.9,112.2z M245,193.8
          c-3.2,2.4-25.5,18.2-61.4,15.8c-1.2-4.1-5.6-21.6-6.2-55.4l-0.1-4c0-1.5,0-3,0-4.5c4.5,1,10.9,1.9,12.1,1c1.7-1.3,1.8-4.8,0.8-5.5
          c-0.6-0.4-8.2-2.3-12.7-2.8c0-1,0-2,0.1-3c14.2-3.2,26.2-13.2,28.2-14.9c1.6-0.4,8.8-2,15.9-0.9c6.5,1.1,11.2,4.2,12.8,5.3v12.5
          l0.9,0.4c0.1,0,8.3,3.6,7.9,9c-0.5,6.6-1.1,14.8-1.1,14.8l-0.1,1.2l1.1,0.4c1.3,0.4,2.7,0.5,4,0.5c1,0,1.9-0.1,2.7-0.2
          C248.9,171.6,247.4,182.2,245,193.8z M249,120.4c-1.1-6.3-5.2-7.3-5.4-7.4l0.4-1.8c0.2,0,5.6,1.3,6.8,8.8L249,120.4z M245.8,107.8
          l-1.8,1.5l0.4-2.3c0,0,0.7-4.9-2.1-10.3l1.6-0.8c2.1,4,2.5,7.6,2.4,9.6c1.9-0.9,5.2-1.7,8.8,0.9l-1,1.5
          C249.7,104.8,246,107.7,245.8,107.8z M264.2,145.8c2.7,2.2,1.3,13.3-3.4,19.7c-2.7,3.6-5.5,4.5-8.5,2.9c0.4-3.3,0.7-6.2,1-8.8
          c0.1-1.1,0.2-2.3,0.3-3.4c0.4-4.3,0.5-6.8,0.5-6.9l-0.1,0c0-0.4,0-0.8,0.1-1.1C255.6,146.6,260.3,142.7,264.2,145.8z"/>
      </g>
      <g ref={lLash} id="l-lash">
        
          <rect x="208.3" y="152.9" transform="matrix(0.9999 -1.093229e-02 1.093229e-02 0.9999 -1.6806 2.3813)" width="17.4" height="4"/>
      </g>
      <g ref={rLash} id="r-lash">
          <rect x="181.1" y="148.6" transform="matrix(3.271331e-02 -0.9995 0.9995 3.271331e-02 22.1249 332.9311)" width="4" height="12.9"/>
      </g>
      <g ref={rEye} id="r-eye">
        <path d="M215.2,162.3L215.2,162.3c1.4,0,2.6-1.2,2.6-2.6v-3.4c0-1.4-1.2-2.6-2.6-2.6l0,0c-1.4,0-2.6,1.2-2.6,2.6v3.4
          C212.6,161.2,213.7,162.3,215.2,162.3z"/>
      </g>
      <g ref={lEye} id="l-eye">
        <path d="M183.6,161.7L183.6,161.7c1.3,0,2.3-1,2.3-2.3v-3c0-1.3-1-2.3-2.3-2.3l0,0c-1.3,0-2.3,1-2.3,2.3v3
          C181.3,160.7,182.3,161.7,183.6,161.7z"/>
      </g>
    </g>
    <g ref={git} id="git">
      <path ref={t} id="t" d="M155.1,28v-5.3h-13.2v-9.5l-6.1,1.6v8h-8.2V28h8.2v14.8c0,3.6,1,6.3,2.9,8.1c1.9,1.7,4.8,2.6,8.6,2.6
        c1.3,0,2.6-0.1,3.8-0.2c1.3-0.1,2.5-0.3,3.6-0.6l0.4-0.1v-5.4l-0.6,0.2c-1.2,0.3-2.3,0.5-3.4,0.7c-3.5,0.5-6.2,0.2-7.6-1.2
        c-1-0.9-1.5-2.4-1.5-4.4V28H155.1z"/>
      <g ref={i} id="i">
        <path d="M106.6,18.1c0.4,0.4,0.9,0.7,1.4,1c0.5,0.2,1.1,0.3,1.8,0.3c0.6,0,1.2-0.1,1.8-0.3c0.5-0.2,1-0.6,1.4-1
          c0.4-0.4,0.7-0.9,1-1.4c0.2-0.5,0.3-1.1,0.3-1.7c0-0.6-0.1-1.2-0.3-1.8c-0.2-0.5-0.5-1-1-1.4c-0.4-0.4-0.9-0.7-1.4-1
          c-1.1-0.5-2.5-0.5-3.5,0c-0.5,0.2-1,0.6-1.4,1c-0.4,0.4-0.7,0.9-1,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.6,0.1,1.2,0.3,1.7
          C105.8,17.2,106.1,17.6,106.6,18.1z"/>
        <polygon points="113.6,22.7 98.8,22.7 98.8,27.9 107.5,27.9 107.5,47.8 97.8,47.8 97.8,53.1 122.4,53.1 122.4,47.8 113.6,47.8 		
          "/>
      </g>
      <path ref={e} id="e" d="M119.9,26c-1.1-1.2-2.5-2.2-4.2-2.9c-1.6-0.7-3.5-1-5.6-1c-2.2,0-4.2,0.4-5.9,1.3c-1.7,0.8-3.2,2-4.4,3.5
        c-1.2,1.4-2.1,3.1-2.7,5c-0.6,1.9-0.9,3.9-0.9,6c0,2.4,0.3,4.6,0.9,6.5c0.6,1.9,1.5,3.6,2.7,5c1.2,1.4,2.8,2.4,4.7,3.1
        c1.9,0.7,4.1,1.1,6.7,1.1c1.9,0,3.7-0.1,5.5-0.4c1.8-0.2,3.4-0.6,4.8-1l0.4-0.1v-5.2l-0.6,0.2c-0.6,0.2-1.3,0.3-2,0.5
        c-0.7,0.1-1.5,0.3-2.3,0.4c-0.8,0.1-1.6,0.2-2.5,0.3c-0.8,0.1-1.7,0.1-2.5,0.1c-3,0-5.3-0.8-6.9-2.3c-1.5-1.4-2.3-3.5-2.4-6.1h20.6
        l0-0.5c0-0.4,0.1-0.9,0.1-1.4c0-0.5,0-1.1,0-1.8c0-2.1-0.3-4.1-0.9-5.8C121.9,28.8,121,27.2,119.9,26z M109.8,27.2
        c1.2,0,2.3,0.2,3.2,0.6c0.9,0.4,1.7,1,2.3,1.7c0.6,0.7,1.1,1.6,1.4,2.6c0.3,0.9,0.4,1.9,0.4,2.9h-14.4c0.1-1.1,0.3-2,0.7-2.9
        c0.4-1,0.9-1.9,1.5-2.7c0.6-0.7,1.4-1.3,2.2-1.7C107.8,27.4,108.8,27.2,109.8,27.2z"/>
      <path ref={g} id="g" d="M78.5,22.3c-0.7-0.1-1.4-0.2-2.2-0.2c-1.8,0-3.4,0.3-4.9,0.8c-1.5,0.5-2.7,1.3-3.8,2.2c-1.1,0.9-1.9,2.1-2.5,3.4
        c-0.6,1.3-0.9,2.8-0.9,4.4c0,0.8,0.1,1.5,0.2,2.1c0.1,0.6,0.3,1.2,0.5,1.7c0.2,0.5,0.5,1,0.8,1.5c0.2,0.3,0.5,0.7,0.7,1
        c-0.7,0.9-1.4,1.8-1.9,2.8c-0.6,1.1-1,2.4-1,3.7c0,0.5,0.1,1,0.2,1.5c0.1,0.5,0.3,1,0.6,1.5c0.3,0.5,0.6,0.9,1,1.3
        c0.2,0.2,0.5,0.4,0.7,0.6c-1.1,0.9-2,1.8-2.6,2.8c-0.7,1.1-1.1,2.5-1.1,4c0,1.2,0.3,2.3,0.8,3.3c0.5,1,1.4,1.9,2.5,2.6
        c1.1,0.7,2.6,1.3,4.3,1.6c1.7,0.4,3.8,0.6,6.2,0.6c2.5,0,4.8-0.3,6.6-0.8c1.9-0.5,3.4-1.3,4.7-2.2c1.2-0.9,2.2-2,2.8-3.3
        c0.6-1.2,0.9-2.6,0.9-3.9c0-1.3-0.3-2.4-0.8-3.4c-0.5-1-1.3-1.8-2.2-2.5c-0.9-0.7-2.1-1.2-3.4-1.5c-1.3-0.3-2.7-0.5-4.2-0.6
        L73,46.9c-1.1,0-1.9-0.3-2.6-0.7c-0.6-0.4-0.9-0.9-0.9-1.4c0-0.5,0.1-1,0.3-1.4c0.2-0.4,0.4-0.7,0.7-1.1c0.6,0.3,1.4,0.6,2.3,0.8
        c1.1,0.3,2.3,0.4,3.6,0.4c1.7,0,3.4-0.3,4.8-0.8c1.5-0.5,2.8-1.3,3.8-2.2c1.1-0.9,1.9-2.1,2.5-3.4c0.6-1.3,0.9-2.8,0.9-4.4
        c0-1.1-0.2-2.1-0.5-3.1c-0.2-0.6-0.5-1.2-0.8-1.7h4.3v-5.2H80.3C79.8,22.5,79.2,22.4,78.5,22.3z M76.7,60.3c-2.5,0-4.5-0.3-5.8-0.9
        C69.6,58.9,69,58,69,56.8c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.2-0.8,0.4-1.1c0.2-0.4,0.5-0.8,0.9-1.1c0.4-0.4,0.8-0.7,1.4-1.1l7.5,0.3
        c1.9,0.1,3.4,0.4,4.3,0.9c0.8,0.5,1.2,1.2,1.2,2.2c0,0.6-0.1,1.1-0.4,1.7c-0.3,0.5-0.7,1-1.3,1.4c-0.6,0.4-1.5,0.8-2.5,1.1
        C79.6,60.2,78.2,60.3,76.7,60.3z M74,38.2c-0.7-0.3-1.3-0.7-1.8-1.2c-0.5-0.5-0.9-1.1-1.2-1.8c-0.3-0.7-0.4-1.5-0.4-2.4
        c0-0.8,0.1-1.5,0.4-2.2c0.3-0.7,0.6-1.3,1.1-1.8c0.5-0.5,1.1-1,1.8-1.3c0.7-0.3,1.6-0.5,2.5-0.5c0.9,0,1.7,0.1,2.4,0.4
        c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.5,0.9,1.1,1.2,1.8c0.3,0.7,0.4,1.5,0.4,2.4c0,0.8-0.1,1.5-0.4,2.2c-0.3,0.7-0.6,1.3-1.1,1.9
        c-0.5,0.5-1.1,1-1.8,1.3C77.5,38.7,75.5,38.7,74,38.2z"/>
    </g>
    </svg>
  )
}